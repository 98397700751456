import * as React from "react";
import { useState } from 'react';
import RemarksModal from '../components/RemarksModal';
import DangerousIcon from '@mui/icons-material/Error';
import { Alert, AlertTitle, Box, Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import pdf1 from "../assets/documents/DS43-Pipe-CapsOver.pdf";
import pdf2 from "../assets/documents/DS42-Pipe-CapsOver.pdf";
import pdf3 from "../assets/documents/DS43-GS-CapsOver.pdf";
import pdf4 from "../assets/documents/DS42-GS-CapsOver.pdf";
import pdf5 from "../assets/documents/DatalogicHandscannerPipe.pdf";
import pdf6 from "../assets/documents/DatalogicMagellanPipe.pdf";
import pdf7 from "../assets/documents/DatalogicHandscannerGS.pdf";
import pdf8 from "../assets/documents/DatalogicMagellanGS.pdf";
import pdf9 from "../assets/documents/NewlandPipe.pdf";
import pdf10 from "../assets/documents/NewlandPipe.pdf";
import pdf11 from "../assets/documents/Honeywell-Genesis-7580g.pdf";
import pdf12 from "../assets/documents/Honeywell-Voyager-1400g.pdf";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(232, 88, 17)"),
  backgroundColor: "rgba(232, 88, 17)",
  "&:hover": {
    backgroundColor: "rgba(232, 88, 17)",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  id: number,
  merk: string,
  type: string,
  sep: string,
  caps: string,
  inverse: string,
  link: string,
  ref: string,
  file_name: string,
  remarks: boolean,
) {
  return { id, merk, type, sep, caps, inverse, link, ref, file_name, remarks };
}

const rows = [
  createData(
    0,
    "Zebra / Motorola / Symbol",
    "DS22 / DS43 / DS46",
    "|",
    "AAN",
    "AAN",
    "Instelblad",
    pdf1,
    "DS43-Pipe-CapsOver.pdf",
    false
  ),
  createData(
    1,
    "Zebra / Motorola / Symbol",
    "DS42",
    "|",
    "AAN",
    "AAN",
    "Instelblad",
    pdf2,
    "DS42-Pipe-CapsOver.pdf",
    false
  ),
  createData(
    2,
    "Zebra / Motorola / Symbol",
    "DS22 / DS43 / DS46",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf3,
    "DS43-GS-CapsOver.pdf",
    false
  ),
  createData(
    3,
    "Zebra / Motorola / Symbol",
    "DS42",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf4,
    "DS42-GS-CapsOver.pdf",
    false
  ),
  createData(
    4,
    "DataLogic",
    "Handscanners (oa. Gryphon)",
    "|",
    "AAN",
    "AAN",
    "Instelblad",
    pdf5,
    "DatalogicHandscannerPipe.pdf",
    false
  ),
  createData(
    5,
    "DataLogic",
    "Magallan (inbouwscanner)",
    "|",
    "AAN",
    "AAN",
    "Instelblad",
    pdf6,
    "DatalogicMagellanPipe.pdf",
    false
  ),
  createData(
    6,
    "DataLogic",
    "Handscanners (oa. Gryphon)",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf7,
    "DatalogicHandscannerGS.pdf",
    false
  ),
  createData(
    7,
    "DataLogic",
    "Magallan (inbouwscanner)",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf8,
    "DatalogicMagellanGS.pdf",
    false
  ),
  createData(
    8,
    "Newland",
    "FR4080 / HR3280 (in cradle plaatsen)",
    "|",
    "AAN",
    "AAN",
    "Instelblad",
    pdf9,
    "NewlandPipe.pdf",
    true
  ),
  createData(
    9,
    "Newland",
    "FR4080 / HR3280 (in cradle plaatsen)",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf10,
    "NewlandGS.pdf",
    true
  ),
  createData(
    10,
    "Honeywell",
    "Genesis 7580g",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf11,
    "Honeywell-Genesis-7580g.pdf",
    false
  ),
  createData(
    11,
    "Honeywell",
    "Voyager 1400g",
    "[GS]",
    "AAN",
    "AAN",
    "Instelblad",
    pdf12,
    "Honeywell-Voyager-1400g.pdf",
    false
  ),
];

export default function ScannerDocumentationPage() {
  const [selectedRecord, setSelectedRecord] = useState<number | null>(null);

  const openRemarksModal = (recordId: number) => {
    setSelectedRecord(recordId);
  };

  const closeRemarksModal = () => {
    setSelectedRecord(null);
  };


  return (
    <Box>
      <Alert sx={{ py: 2, mb: 2 }} severity="warning" variant="filled">
        <AlertTitle>WAARSCHUWING</AlertTitle>
        Belangrijk: De NMVO raadt u aan om alleen samen met uw IT-ondersteuning
        uw scanner opnieuw te configureren. Uw IT-ondersteuning zorgt dat de
        configuratie van de scanner geen invloed heeft op andere systemen of
        toepassingen die ook afhankelijk zijn van de scanners in uw apotheek. De
        NMVO kan geen garantie bieden dat onderstaande instelbladen voor uw
        systeem werken.
      </Alert>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          <b>Algemene instructies configuratie scanner</b>
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Hieronder vindt u scanner configuratiebladen: dit zijn instelbladen
          (configuraties) waarmee de meest gebruikte scanners met 1 scan juist
          kunnen worden ingesteld. Na het opnieuw instellen van de scanner is
          het raadzaam om <u>altijd</u> opnieuw de Scanner Test-Tool te
          doorlopen om te controleren of de scanner inderdaad goed is ingesteld.
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Maakt u gebruik van een scannermerk of scannertype die niet voorkomt
          in de lijst met scanner configuratiebladen? Dan kunt u geen gebruik
          maken van een instelblad en zult u de Instellingseisen moeten
          gebruiken: deze vindt u verder op de pagina. Wij raden u aan uw
          IT-installatiepartij in te schakelen voor hulp bij het opnieuw
          instellen van uw scanner. Maak na het opnieuw instellen van uw scanner{" "}
          <u>altijd</u> gebruik van de Scanner Test-Tool om te controleren of de
          scanner inderdaad goed is ingesteld.
        </Typography>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">
          <b>Scanner configuratiebladen</b>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Hieronder vindt u enkele instelbladen waarmee de scanner kan worden
          geconfigureerd. Hiervoor is rekening gehouden met bovenstaande eisen
          waaraan een scanner moet voldoen.
        </Typography>
        <b>
          <u>Belangrijk</u>
        </b>
        <ul>
          <li>
            <b>LET OP!</b> De instelbladen zijn <u>uitsluitend</u> bedoeld voor
            aansluitingen onder de genoemde AIS-leveranciers.
          </li>
          <li>
            Controleer goed welke separator (splitsingssymbool) er nodig is voor
            uw AIS-systeem.
          </li>

          <li>
            Let op, het klopt dat een instelblad kan werken op meerdere
            type/merken scanners.
          </li>
          <li>
            Let op, na het configureren van een <u>draadloze</u> scanner dient
            deze opnieuw gekoppeld te worden met het base station.
          </li>
          <li>
            Na het configureren van de scanner dient deze opnieuw getest te
            worden.
          </li>
          <li>
            Heeft u andere correcte instelbladen of verdere vragen/opmerkingen?
            Wij horen dit dan graag van u via{" "}
            <a href="mailto:apotheek@nmvo.nl">apotheek@nmvo.nl</a>.
          </li>
        </ul>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead style={{ fontWeight: 900 }}>
              <TableRow>
                <StyledTableCell>Merk scanner</StyledTableCell>
                <StyledTableCell align="right">Type</StyledTableCell>
                <StyledTableCell align="right">Separator</StyledTableCell>
                <StyledTableCell align="right">
                  CapsLock-override
                </StyledTableCell>
                <StyledTableCell align="right">Inverse-Modus</StyledTableCell>
                <StyledTableCell align="right">Download Link</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow
                  key={row.merk}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.merk}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.type}</StyledTableCell>
                  <StyledTableCell align="right">{row.sep}</StyledTableCell>
                  <StyledTableCell align="right">{row.caps}</StyledTableCell>
                  <StyledTableCell align="right">{row.inverse}</StyledTableCell>
                  <StyledTableCell align="right">
                    <a href={row.ref} download={row.file_name}>
                      {row.link}
                    </a>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.remarks && (
                      <DangerousIcon onClick={() => openRemarksModal(row.id)}></DangerousIcon>
                     )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedRecord !== null && (
        <RemarksModal
          isOpen={true}
          onRequestClose={closeRemarksModal}
        />
      )}
        <ColorButton
          variant="contained"
          href="scanner-ais"
          sx={{
            mt: 2,
            mb: 0,
            color: "white",
            justify: "space-between",
          }}
        >
          Scanner opnieuw testen
        </ColorButton>
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          <u>
            <b>Instellingseisen (overige) scanners</b>
          </u>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <b>Stap 1:</b> Juiste separator (splitsings-symbool) instellen.
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          De informatie van de gescande 2D-code moet in stukken (Batch-ID, GTIN,
          Exp.date en Serienummer) worden doorgestuurd naar het computersysteem
          (AIS/HIS). Hiervoor moet de gescande 2D-code op bepaalde punten worden
          gesplitst. Per AIS/HIS verschilt het welk splitsings-symbool
          (separator) er moet worden ingesteld in de scanner. Vraag hiervoor bij
          uw AIS-leverancier welk symbool dit moet zijn.
          <ul>
            <li>
              <u>Pharmacom:</u> Pipe symbol ('|')
            </li>
            <li>
              <u>CGM:</u> Group Symbol ('[GS]')
            </li>
            <li>
              <u>Isatis:</u> Meerdere separators toegestaan(bijvoorbeeld '|')
            </li>
            <li>
              <u>PI-Farma (Odessa / Loxis):</u> Meerdere separators toegestaan
              (voorkeur voor '|')
            </li>
            <li>
              <u>Sanday (APRO):</u> Group Symbol ('[GS]') of Function 1 ('&lt;FNC1&gt;')
            </li>
          </ul>
        </Typography>
        <br></br>
        <Typography variant="subtitle1" gutterBottom>
          <b>Stap 2:</b> Hoofdlettergevoeligheid.
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Binnen uw apotheek-systeem, kunnen er plekken zijn waar het
          makkelijker is als de CapsLock altijd aan staat. Hierdoor komt het
          voor dat de CapsLock aan blijft tijdens het FMD-scannen waardoor veel
          alerts ontstaan. Het is mogelijk de scanner zó in te stellen, dat de
          scanner de CapsLock-toets 'negeert' en de 2D-code van een
          medicijnverpakking juist doorstuurt. Deze instelling moet vaak wel
          worden aangezet.
          <ul>
            <li>CapsLock override</li>
            <Typography variant="body2">
              Hierbij wordt de CapsLock-toets op het toetsenbord overruled.
              Echter kunnen toetsenbordinstellingen (toetsenbordtaal) nog steeds
              van invloed zijn op de scans, let erop dat dit juist staat
              ingesteld en overeenkomt met het aangesloten toetsenbord.
            </Typography>
          </ul>
        </Typography>
        <br></br>
        <Typography variant="subtitle1" gutterBottom>
          <b>Stap 3:</b> Inverse-mode (donkere pakjes met witte 2D-code).
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Het komt voor dat fabrikanten een donkere verpakking gekozen hebben,
          vaak wordt er dan gekozen om een witte 2D-code te plaatsen op de
          verpakkingen in plaats van de standaard zwarte codes. De scanner dient
          ingesteld te zijn om deze witte codes te kunnen lezen.
        </Typography>

        <br></br>
        <Typography variant="subtitle1" gutterBottom>
          <b>Stap 4:</b> Toetsenbordinstellingen / leessnelheid.
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Eigenlijk is de scanner een verlengstuk van het toetsenbord. Hierdoor
          kan het zijn dat de toetsenbordinstellingen invloed hebben op de
          scans. Zorg dat u let op de volgende toetsenbordinstellingen:
          <ul>
            <li>
              Selecteer slechts 1 toetsenbordtaal in Windows (vaak US
              International). Hiermee voorkomt u dat een '/' in een scan wordt
              omgezet naar een '-'.
            </li>
            <li>
              Zeer belangrijk: zorg dat de toetsenbordinstelling in Windows
              aansluit met de lay-out van het toetsenbord zelf.
            </li>
            <li>
              Zet de leessnelheid van de scanner niet te snel: bij een te hoge
              leessnelheid kan het voorkomen dat tekens in de code worden
              omgewisseld.
            </li>
          </ul>
        </Typography>
        <br></br>
        <Typography variant="subtitle1" gutterBottom>
          <b>Overige configuratie-mogelijkheden</b> .
        </Typography>
        <Typography paragraph variant="body1" gutterBottom>
          Het is mogelijk om veel verschillende instellingen van uw scanner aan
          te passen naar uw wens. Als u bijvoorbeeld gebruikt maakt van een
          draadloze scanner, dient na het configureren van de scanner een
          koppeling gemaakt te worden met het base-station. Ook is het mogelijk
          om een scanner altijd 'aan' te zetten, zodat u een verpakking erlangs
          kunt halen en niet iedere keer hoeft te klikken. Raadpleeg hiervoor de
          handleiding van uw merk/type.
        </Typography>

        <ColorButton
          variant="contained"
          href="scanner-ais"
          sx={{
            mt: 2,
            mb: 0,
            color: "white",
            justify: "space-between",
          }}
        >
          Scanner opnieuw testen
        </ColorButton>
      </Paper>
    </Box>
  );
}
